export function getAddonsFromProduct (product) {
  return product?.addons?.map(e => e.value)
    .filter(e => {
      if (Array.isArray(e) && e.length) {
        return e
      } else if (!!e && (e.constructor === Object) && Object.keys(e).length) {
        return e
      } else {
        return false
      }
    })
    .flat(1)
}
