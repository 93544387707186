<template>
  <div class="fixed z-50 flex h-screen w-full items-center justify-center bg-white">
    <div
      ref="lottieContainer"
      class="w-1/2"
    />
  </div>
</template>
<script setup>
import { onMounted, ref } from 'vue'
import lottie from 'lottie-web'
import lottieLoading from 'assets/loading.json'

const lottieContainer = ref()

onMounted(() => {
  lottie.loadAnimation({
    container: lottieContainer.value,
    animationData: lottieLoading,
    renderer: 'svg',
    loop: true,
    autoplay: true
  })
})
</script>
