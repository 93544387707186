const categories = [
  {
    id: 'category-combos',
    name: 'combos',
    items: [
      {
        id: 'acai',
        name: 'Açai 500ml',
        description: 'Um delicioso açai do norte',
        image: 'https://img.freepik.com/fotos-gratis/close-de-angulo-alto-de-uma-tigela-branca-com-fatias-de-morango-e-banana_181624-39472.jpg?w=2000&t=st=1701799488~exp=1701800088~hmac=3a36ae3cab8778b9f5abeb21d03b6e41ff01fcfc853b54bbc770c8949803fe98',
        price: {
          unitary: 12.90
        },
        addons: [
          {
            id: 'adicionais',
            label: 'Adicionais:',
            multiple: true,
            required: false,
            value: [],
            items: [
              { id: 'leite-condensado', label: 'Leite Condensado', price: 3, showPrice: true },
              { id: 'chocoball', label: 'Chocoball', price: 4, showPrice: true },
              { id: 'pacoca', label: 'Paçoca', price: 2, showPrice: true }
            ]
          },
          {
            id: 'precisa-de-colher',
            label: 'Precisa de colher e canudo?',
            multiple: false,
            required: true,
            value: { id: 'nao', label: 'Não', price: 0, showPrice: false },
            items: [
              { id: 'sim', label: 'Sim', price: 0, showPrice: false },
              { id: 'nao', label: 'Não', price: 0, showPrice: false }
            ]
          }
        ]
      },
      {
        id: 'x-bacon-egg',
        name: 'X Bacon Egg',
        description:
          'Um delicioso lanche com bacon e ovo, feito com hambúrguer caseiro',
        image:
          'https://img.freepik.com/fotos-gratis/batatas-fritas-crocantes-com-ketchup-e-maionese_1150-26588.jpg?w=2000&t=st=1694823216~exp=1694823816~hmac=cce59e39e6b699aaa749e882ec5e0f50e2fbd2e3ba105cfa78f53858a63a17b0',
        addons: [],
        price: {
          unitary: 32.90,
          combo: 37.90
        }
      },
      {
        id: 'x-bacon-egg2',
        name: 'X Bacon Egg',
        description:
          'Um delicioso lanche com bacon e ovo, feito com hambúrguer caseiro',
        image:
          'https://img.freepik.com/fotos-gratis/batatas-fritas-crocantes-com-ketchup-e-maionese_1150-26588.jpg?w=2000&t=st=1694823216~exp=1694823816~hmac=cce59e39e6b699aaa749e882ec5e0f50e2fbd2e3ba105cfa78f53858a63a17b0',
        addons: [],
        price: {
          unitary: 32.90,
          combo: 37.90
        }
      },
      {
        id: 'x-bacon-egg3',
        name: 'X Bacon Egg',
        description:
          'Um delicioso lanche com bacon e ovo, feito com hambúrguer caseiro',
        image:
          'https://img.freepik.com/fotos-gratis/batatas-fritas-crocantes-com-ketchup-e-maionese_1150-26588.jpg?w=2000&t=st=1694823216~exp=1694823816~hmac=cce59e39e6b699aaa749e882ec5e0f50e2fbd2e3ba105cfa78f53858a63a17b0',
        addons: [],
        price: {
          unitary: 32.90,
          combo: 37.90
        }
      }
    ]
  },
  {
    id: 'category-burgers',
    name: 'Hamburguers',
    items: [
      {
        id: 'classic-burger',
        name: 'Classic Burger',
        description:
          'Um hambúrguer clássico com carne, queijo, alface e tomate',
        image:
          'https://img.freepik.com/fotos-gratis/batatas-fritas-crocantes-com-ketchup-e-maionese_1150-26588.jpg?w=2000&t=st=1694823216~exp=1694823816~hmac=cce59e39e6b699aaa749e882ec5e0f50e2fbd2e3ba105cfa78f53858a63a17b0',
        addons: [],
        price: {
          unitary: 14.90,
          combo: 18.90
        }
      },
      {
        id: 'cheeseburger',
        name: 'Cheeseburger',
        description:
          'Hambúrguer suculento com queijo cheddar derretido e molho especial',
        image:
          'https://img.freepik.com/fotos-gratis/batatas-fritas-crocantes-com-ketchup-e-maionese_1150-26588.jpg?w=2000&t=st=1694823216~exp=1694823816~hmac=cce59e39e6b699aaa749e882ec5e0f50e2fbd2e3ba105cfa78f53858a63a17b0',
        addons: [],
        price: {
          unitary: 15.90,
          combo: 19.90
        }
      },
      {
        id: 'veggie-burger',
        name: 'Veggie Burger',
        description:
          'Um hambúrguer vegetariano com base de feijão preto e vegetais',
        image:
          'https://img.freepik.com/fotos-gratis/batatas-fritas-crocantes-com-ketchup-e-maionese_1150-26588.jpg?w=2000&t=st=1694823216~exp=1694823816~hmac=cce59e39e6b699aaa749e882ec5e0f50e2fbd2e3ba105cfa78f53858a63a17b0',
        addons: [],
        price: {
          unitary: 12.90,
          combo: 16.90
        }
      }
    ]
  },
  {
    id: 'category-pizzas',
    name: 'Pizzas',
    items: [
      {
        id: 'margherita-pizza',
        name: 'Pizza Margherita',
        description:
          'Pizza clássica com molho de tomate, mussarela e manjericão fresco',
        image:
          'https://img.freepik.com/fotos-gratis/batatas-fritas-crocantes-com-ketchup-e-maionese_1150-26588.jpg?w=2000&t=st=1694823216~exp=1694823816~hmac=cce59e39e6b699aaa749e882ec5e0f50e2fbd2e3ba105cfa78f53858a63a17b0',
        addons: [],
        price: {
          unitary: 16.90,
          combo: 22.90
        }
      },
      {
        id: 'pepperoni-pizza',
        name: 'Pizza de Pepperoni',
        description: 'Pizza com molho de tomate, queijo e pepperoni picante',
        image:
          'https://img.freepik.com/fotos-gratis/batatas-fritas-crocantes-com-ketchup-e-maionese_1150-26588.jpg?w=2000&t=st=1694823216~exp=1694823816~hmac=cce59e39e6b699aaa749e882ec5e0f50e2fbd2e3ba105cfa78f53858a63a17b0',
        addons: [],
        price: {
          unitary: 18.90,
          combo: 24.90
        }
      },
      {
        id: 'vegetarian-pizza',
        name: 'Pizza Vegetariana',
        description:
          'Pizza com molho de tomate, queijo e uma variedade de legumes frescos',
        image:
          'https://img.freepik.com/fotos-gratis/batatas-fritas-crocantes-com-ketchup-e-maionese_1150-26588.jpg?w=2000&t=st=1694823216~exp=1694823816~hmac=cce59e39e6b699aaa749e882ec5e0f50e2fbd2e3ba105cfa78f53858a63a17b0',
        addons: [],
        price: {
          unitary: 17.90,
          combo: 23.90
        }
      }
    ]
  },
  {
    id: 'category-sushi',
    name: 'Sushi',
    items: [
      {
        id: 'sashimi-platter',
        name: 'Sashimi Platter',
        description:
          'Uma seleção de sashimi fresco de salmão, atum e peixe branco',
        image:
          'https://img.freepik.com/fotos-gratis/batatas-fritas-crocantes-com-ketchup-e-maionese_1150-26588.jpg?w=2000&t=st=1694823216~exp=1694823816~hmac=cce59e39e6b699aaa749e882ec5e0f50e2fbd2e3ba105cfa78f53858a63a17b0',
        addons: [],
        price: {
          unitary: 29.90,
          combo: 35.90
        }
      },
      {
        id: 'dragon-roll',
        name: 'Dragon Roll',
        description:
          'Rolo de sushi com camarão tempura e abacate, coberto com eel e abacate',
        image:
          'https://img.freepik.com/fotos-gratis/batatas-fritas-crocantes-com-ketchup-e-maionese_1150-26588.jpg?w=2000&t=st=1694823216~exp=1694823816~hmac=cce59e39e6b699aaa749e882ec5e0f50e2fbd2e3ba105cfa78f53858a63a17b0',
        addons: [],
        price: {
          unitary: 22.90,
          combo: 28.90
        }
      },
      {
        id: 'vegetarian-sushi',
        name: 'Sushi Vegetariano',
        description:
          'Variedade de sushi vegetariano com legumes frescos e abacate',
        image:
          'https://img.freepik.com/fotos-gratis/batatas-fritas-crocantes-com-ketchup-e-maionese_1150-26588.jpg?w=2000&t=st=1694823216~exp=1694823816~hmac=cce59e39e6b699aaa749e882ec5e0f50e2fbd2e3ba105cfa78f53858a63a17b0',
        addons: [],
        price: {
          unitary: 19.90,
          combo: 25.90
        }
      }
    ]
  },
  {
    id: 'category-desserts',
    name: 'Sobremesas',
    items: [
      {
        id: 'chocolate-cake',
        name: 'Bolo de Chocolate',
        description: 'Uma fatia de bolo de chocolate com cobertura de ganache',
        image:
          'https://img.freepik.com/fotos-gratis/batatas-fritas-crocantes-com-ketchup-e-maionese_1150-26588.jpg?w=2000&t=st=1694823216~exp=1694823816~hmac=cce59e39e6b699aaa749e882ec5e0f50e2fbd2e3ba105cfa78f53858a63a17b0',
        addons: [],
        price: {
          unitary: 8.90,
          combo: 11.90
        }
      },
      {
        id: 'ice-cream-sundae',
        name: 'Sundae de Sorvete',
        description:
          'Sundae com sorvete de baunilha, calda de caramelo e chantilly',
        image:
          'https://img.freepik.com/fotos-gratis/batatas-fritas-crocantes-com-ketchup-e-maionese_1150-26588.jpg?w=2000&t=st=1694823216~exp=1694823816~hmac=cce59e39e6b699aaa749e882ec5e0f50e2fbd2e3ba105cfa78f53858a63a17b0',
        addons: [],
        price: {
          unitary: 7.90,
          combo: 10.90
        }
      },
      {
        id: 'fruit-salad',
        name: 'Salada de Frutas',
        description: 'Uma salada de frutas frescas com mel e hortelã',
        image:
          'https://img.freepik.com/fotos-gratis/batatas-fritas-crocantes-com-ketchup-e-maionese_1150-26588.jpg?w=2000&t=st=1694823216~exp=1694823816~hmac=cce59e39e6b699aaa749e882ec5e0f50e2fbd2e3ba105cfa78f53858a63a17b0',
        addons: [],
        price: {
          unitary: 6.90,
          combo: 9.90
        }
      }
    ]
  }
]

const page = {
  banners: [
    {
      id: 'x-bacon-egg',
      image: 'https://firebasestorage.googleapis.com/v0/b/avivadelivery-1a1c6.appspot.com/o/Post%20para%20instagram%20de%20fast%20food%20chamativo%20laranja.png?alt=media&token=34ff3f15-b900-4639-8f58-84df7e43a6dc',
      title: 'Um saboroso lanche',
      text: 'a partir de 24,90'
    },
    {
      id: 'x-bacon-egg',
      image: 'https://firebasestorage.googleapis.com/v0/b/avivadelivery-1a1c6.appspot.com/o/Instagram%20retrato%20de%20hambu%CC%81rguer%20ousado%20marrom%20e%20laranja%20.png?alt=media&token=e26f1fd5-00e5-45ab-a793-886963e4267a',
      title: 'Um saboroso lanche',
      text: 'a partir de 24,90'
    }
  ]
}

export default categories

export { categories, page }
