import vine from '@vinejs/vine'

const schema = vine.object({
  banners: vine.array(
    vine.object({
      image: vine.string(),
      position: vine.number({ strict: true }),
      pushTo: vine.string(),
      text: vine.string(),
      title: vine.string(),
    })
  ),
  createdAt: vine.object({
    seconds: vine.number({ strict: true }).positive().withoutDecimals(),
    nanoseconds: vine.number({ strict: true }).positive().withoutDecimals()
  }),
  logo: vine.string(),
  name: vine.string(),
  open: vine.boolean(),
  openUntil: vine.string(),
  openingHours: vine.object({
    monday: vine.object({
      active: vine.boolean(),
      end: vine.string(),
      init: vine.string(),
      label: vine.string(),
    }),
    tuesday: vine.object({
      active: vine.boolean(),
      end: vine.string(),
      init: vine.string(),
      label: vine.string(),
    }),
    wednesday: vine.object({
      active: vine.boolean(),
      end: vine.string(),
      init: vine.string(),
      label: vine.string(),
    }),
    thursday: vine.object({
      active: vine.boolean(),
      end: vine.string(),
      init: vine.string(),
      label: vine.string(),
    }),
    friday: vine.object({
      active: vine.boolean(),
      end: vine.string(),
      init: vine.string(),
      label: vine.string(),
    }),
    saturday: vine.object({
      active: vine.boolean(),
      end: vine.string(),
      init: vine.string(),
      label: vine.string(),
    }),
    sunday: vine.object({
      active: vine.boolean(),
      end: vine.string(),
      init: vine.string(),
      label: vine.string(),
    }),
  }),
  socialMedia: vine.object({
    bio: vine.string(),
    facebook: vine.string(),
    instagram: vine.string(),
    whatsapp: vine.string()
  })
})

const data = {
  banners: [
    {
      image: '',
      position: 0,
      pushTo: '',
      text: '',
      title: ''
    }
  ],
  createdAt: {
    seconds: 0,
    nanoseconds: 0
  },
  logo: '',
  name: '',
  open: false,
  openUntil: '',
  openingHours: {
    monday: {
      active: false,
      end: '0',
      init: '0',
      label: 'Segunda'
    },
    tuesday: {
      active: false,
      end: '0',
      init: '0',
      label: 'Terça'
    },
    wednesday: {
      active: false,
      end: '0',
      init: '0',
      label: 'Quarta'
    },
    thursday: {
      active: false,
      end: '0',
      init: '0',
      label: 'Quinta'
    },
    friday: {
      active: false,
      end: '0',
      init: '0',
      label: 'Sexta'
    },
    saturday: {
      active: false,
      end: '0',
      init: '0',
      label: 'Sábado'
    },
    sunday: {
      active: false,
      end: '0',
      init: '0',
      label: 'Domingo'
    }
  },
  socialMedia: {
    bio: '',
    facebook: '',
    instagram: '',
    whatsapp: ''
  }
}

async function validateCompany (company) {
  const dataToValidate = company ? { schema, data: { ...company } } : { schema, data }

  return await vine.validate(dataToValidate)
}

export { validateCompany }
