const routes = [
  {
    path: '/',
    component: () => import('src/LayoutMain.vue'),
    children: [
      {
        path: '/',
        name: 'HomePage',
        component: () => import('src/pages/HomePage.vue')
      },

      {
        path: '/store',
        name: 'StorePage',
        component: () => import('src/pages/StorePage.vue')
      },

      {
        path: '/menu',
        name: 'MenuPage',
        component: () => import('src/pages/MenuPage.vue')
      },
      {
        path: '/item/:id',
        name: 'ItemPage',
        component: () => import('src/pages/ItemPage.vue')
      },
      {
        path: '/error',
        component: () => import('src/pages/NotFound.vue'),
        name: 'ErrorPage'
      },
      {
        path: '/:catchAll(.*)',
        component: () => import('src/pages/NotFound.vue'),
        name: 'NotFound'
      }
    ]
  }
]

export default routes
