// Tipos de refeições
export const meal = {
  combo: {
    label: 'Combo',
    value: 'combo'
  },
  unitary: {
    label: 'Individual',
    value: 'unitary'
  }
}
