import { createApp } from 'vue'
import { createPinia } from 'pinia'
import App from 'src/App.vue'
import router from './router'
import 'boot/firebase'
import './config.js'
import aviva from '@aviva/core'

const pinia = createPinia()

const app = createApp(App)

app.provide('aviva', aviva)

app.use(router).use(pinia).mount('#app')

if ('serviceWorker' in navigator ) {
  navigator.serviceWorker.register(new URL("./service-worker.js", import.meta.url), { scope: './' })
}
