import vine from '@vinejs/vine'

const schema = vine.object({
  name: vine.string(),
  slug: vine.string(),
  image: vine.string(),
  visible: vine.boolean(),
  created_at: vine.object({
    seconds: vine.number({ strict: true }).positive().withoutDecimals(),
    nanoseconds: vine.number({ strict: true }).positive().withoutDecimals()
  }),
  updated_at: vine.object({
    seconds: vine.number({ strict: true }).positive().withoutDecimals(),
    nanoseconds: vine.number({ strict: true }).positive().withoutDecimals()
  }),
})

const data = {
  name: '',
  slug: '',
  image: '',
  visible: false,
  created_at: {
    seconds: 0,
    nanoseconds: 0
  },
  updated_at: {
    seconds: 0,
    nanoseconds: 0
  }
}

async function validateCategory (category) {
  const dataToValidate = category ? { schema, data: { ...category } } : { schema, data }

  return await vine.validate(dataToValidate)
}

export { validateCategory }
