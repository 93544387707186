export function mockHttp (success, timeout = 1000) {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      if (success) {
        resolve()
      } else {
        reject(new Error('Erro!'))
      }
    }, timeout)
  })
}
