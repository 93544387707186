import { defineStore } from 'pinia'
import { ref } from 'vue'
import { doc, onSnapshot } from 'firebase/firestore'
import { useRouter } from 'vue-router'
import { useLoadingStore } from 'stores/LoadingStore'
import { db } from 'boot/firebase'
import aviva from '@aviva/core'

export const useRestaurantStore = defineStore('restaurant', () => {
  const data = ref({})
  const host = window.location.host
  const loaded = ref(false)

  function boot () {
    const docRef = doc(db, 'restaurants', host)
    const router = useRouter()
    const loadingStore = useLoadingStore()

    const restaurantSession = sessionStorage.getItem('restaurant')

    if (restaurantSession) {
      this.set(JSON.parse(restaurantSession))
    }

    loadingStore.loading = true

    onSnapshot(docRef, doc => {
      const data = doc.data()

      if (data && Object.keys(data).length) {
        this.set(data)
        sessionStorage.setItem('restaurant', JSON.stringify(data))
      } else {
        router.push({ name: 'ErrorPage' })
      }

      loadingStore.loading = false
    },
    error => {
      if (error) {
        loadingStore.loading = false
        router.push({ name: 'ErrorPage' })
      }
    })
  }

  async function set (restaurant) {
    try {
      data.value = await aviva.schemas.validateCompany(restaurant)
      loaded.value = true
    } catch (err) {
      console.error(err)
    }
  }

  return {
    data,
    host,
    loaded,
    set,
    boot
  }
})
