/**
 * function responsible for converting the firebase date
 * @date 31/01/2024 - 11:56:13
 *
 * @export
 * @param {object} timestamp
 * @param {number} timestamp.seconds
 * @param {number} timestamp.nanoseconds
 * @returns {Date}
 */
export function convertDate (timestamp) {
  const seconds = timestamp.seconds
  const nanoseconds = timestamp.nanoseconds

  const jsDate = new Date(seconds * 1000 + nanoseconds / 1e6)

  return jsDate.toLocaleString()
}
